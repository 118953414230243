export enum account_type {
  admin = "ADM", //Administrador
  client = "SELLER" //Vendedor
};

export enum itens_per_page {
  default = 10
};

export enum statusCode {
  ok = 200,
  created = 201,
  updated = 204
};