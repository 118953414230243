import { useEffect, useState } from "react";
import { State } from "../interfaces/company";
import { getStateAll } from "../services/company";

interface SelectStateProps {
  selectedState: string;
  setSelectedState: Function;
  showAll?: boolean;
  selectAll?: boolean;
}

const SelectState = ({
  selectedState,
  setSelectedState,
  showAll,
  selectAll,
}: SelectStateProps) => {
  const [list, setList] = useState<Array<State>>([]);
  useEffect(() => {
    getStateAll().then((response) => {
      setList(response);
    });
  }, []);

  return (
    <>
      <label className="form-label">Estado</label>
      <select
        className="form-control form-select"
        onChange={(e) => setSelectedState(e.target.value)}
        value={selectedState}
      >
        {!!selectAll && (
          <option value="">{showAll ? "Todos" : "Selecione"}</option>
        )}
        {!!list &&
          list.map((item) => (
            <option value={item.id_state}>{item.name}</option>
          ))}
      </select>
    </>
  );
};

export default SelectState;
