import { Formik } from "formik";
import jwtDecode from "jwt-decode";
import { Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { account_type, statusCode } from "../../../beans/enumerators";
import { messagesForm } from "../../../beans/messages";
import useToken from "../../../utils/hooks/useToken";
import { TokenUser } from "../../../utils/interfaces/token";
import { isValidToken, setSession } from "../../../utils/token";
import { SignInForm } from "../interfaces/auth";
import { signInApi } from "../services/auth";

const SignIn = () => {
  const navigate = useNavigate();
  const { setIsValid, setValue, setUser, setIsAdmin } = useToken();

  const initialValues: SignInForm = { submit: false };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(messagesForm.invalid)
          .max(100)
          .required(messagesForm.required),
        password: Yup.string().max(50).required(messagesForm.required),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        await signInApi(values).then((response) => {
          if (response.status !== statusCode.ok) {
            setStatus({ success: false });
            setSubmitting(false);
          } else {
            let token = response.statusText;
            setSession(token);
            let decodeUser = jwtDecode<TokenUser>(token);
            setIsValid(isValidToken(token));
            setValue(token);
            setUser(decodeUser);
            setIsAdmin(decodeUser.account_type === account_type.admin);
            navigate("/");
          }
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              size="lg"
              type="email"
              name="email"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <small className="mt-4 d-block">
            Esqueci minha senha.{" "}
            <Link to="/acesso/recuperar-senha/">Recuperar</Link>
          </small>

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Acessar
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignIn;
