import { Navigate } from "react-router-dom";

import useToken from "../../utils/hooks/useToken";

interface AuthGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }: AuthGuardType) {
  const { isValid } = useToken();

  if (!isValid) return <Navigate to="/acesso/entrar" />;

  return <>{children}</>;
}

export default AuthGuard;
