import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { Product, ProductList } from "../interfaces/product";

export async function getProductAll(filters?: any): Promise<ProductList> {
  return axiosInstance.get('/product/?' + new URLSearchParams(filters).toString()).then(response => 
    verifyDataPagination(response)
  ).catch(error => verifyDataPagination(error));
}

export async function importProduct(file: any): Promise<AxiosResponse> {
  return axiosInstance.post('/product/', file).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function postProduct(data: Product): Promise<AxiosResponse> {
  return axiosInstance.post('/product/create/', data).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function updateProduct(data: Product): Promise<AxiosResponse> {
  return axiosInstance.put('/product/', data).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function updateStatusProduct(data: Product): Promise<AxiosResponse> {
  return axiosInstance.patch('/product/', data).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function deleteProduct(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/product/' + uuid).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function getProduct(uuid: string): Promise<Product | undefined> {
  return axiosInstance.get('/product/' + uuid).then(response => 
    verifyData(response)
  ).catch(error => verifyData(error));
}