import { Col, Row, Collapse, Card } from "react-bootstrap";

import FilterActive from "../../../components/lists/FilterActive";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
}

const Filter = ({ verifyFilters, openFilters }: FilterProps) => {
  return (
    <Collapse in={openFilters} className="mq-filter p-3">
      <Card>
        <Row className="mt-n3">
          <FilterActive verifyFilters={verifyFilters} />
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
