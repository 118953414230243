import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";

import { Container, Card, Col } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormProduct from "./components/FormProduct";

import { Product } from "./interfaces/product";
import {
  deleteProduct,
  getProduct,
  updateStatusProduct,
} from "./services/product";

import { statusCode } from "../../beans/enumerators";
import BtnDropdown from "../../components/lists/BtnDropdown";
import { messagesAction } from "../../beans/messages";
import { faBan, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const ProductUpdate = () => {
  const [productSelected, setProductSelected] = useState<Product>();
  const navigate = useNavigate();
  const { id } = useParams();

  function goBack() {
    navigate("/produtos/");
  }

  useEffect(() => {
    if (id) {
      getProduct(id).then((response) => {
        if (response) {
          setProductSelected(response);
        } else {
          goBack();
        }
      });
    } else {
      goBack();
    }
  }, [id]);

  function handleStatus(status: boolean) {
    document.getElementsByTagName("h1")[0].click();
    if (productSelected) {
      let newProduct: Product = { ...productSelected };
      newProduct.is_active = status;
      updateStatusProduct(newProduct).then((response) => {
        if (response.status === statusCode.updated)
          setProductSelected(newProduct);
      });
    }
  }

  function handleDelete() {
    document.getElementsByTagName("h1")[0].click();
    if (productSelected?.uuid_product) {
      deleteProduct(productSelected.uuid_product).then((response) => {
        if (response.status === statusCode.updated) {
          toast.success(messagesAction.deleted);
          goBack();
        }
      });
    }
  }

  return (
    <>
      <Helmet title={"Editar produto: " + productSelected?.name} />
      <Container fluid className="p-0">
        <Header title={"Editar produto: " + productSelected?.name}>
          <Col xs="auto">
            {productSelected?.is_active ? (
              <BtnDropdown
                btn={messagesAction.inactive}
                icon={faBan}
                title={messagesAction.inactive}
                msg={
                  "Tem certeza que deseja " +
                  messagesAction.inactive +
                  " o produto?"
                }
                variant_color="warning"
                handleAction={() => handleStatus(false)}
              />
            ) : (
              <BtnDropdown
                btn={messagesAction.active}
                icon={faCheck}
                title={messagesAction.active}
                msg={
                  "Tem certeza que deseja " +
                  messagesAction.active +
                  " o produto?"
                }
                variant_color="success"
                handleAction={() => handleStatus(true)}
              />
            )}
          </Col>
          <Col xs="auto">
            <BtnDropdown
              btn={messagesAction.delete}
              icon={faTrash}
              title={messagesAction.delete}
              msg={
                "Tem certeza que deseja " +
                messagesAction.delete +
                " o produto? Essa ação não pode ser desfeita."
              }
              variant_color="danger"
              handleAction={handleDelete}
            />
          </Col>
        </Header>
        <Card className="p-3">
          <FormProduct
            productSelected={productSelected}
            setProductSelected={setProductSelected}
          />
        </Card>
      </Container>
    </>
  );
};

export default ProductUpdate;
