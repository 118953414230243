import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

import SignIn from "./components/SignIn";
import LogoWhite from "./components/LogoWhite";

const SignInPage = () => (
  <>
    <Helmet title="Entrar" />
    <Row className="h-xl-100 align-items-center py-4 g-0">
      <LogoWhite />
      <Col xl={5}>
        <div className="block-max-width mx-auto mt-5 mt-xl-0">
          <div className="text-center">
            <h2>Bem-vindo(a) de volta</h2>
            <p className="lead">
              Informe seus dados para acessar ou{" "}
              <Link to="/acesso/cadastrar/">cadastre-se aqui</Link>
            </p>
          </div>
          <SignIn />
        </div>
      </Col>
    </Row>
  </>
);

export default SignInPage;
