import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { messagesAction } from "../../../beans/messages";
import { deleteCompany, updateStatusCompany } from "../services/company";
import { statusCode } from "../../../beans/enumerators";
import { Company } from "../interfaces/company";
import BtnDropdown from "../../../components/lists/BtnDropdown";
import { faBan, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

interface ModalViewProps {
  companySelected: Company | undefined;
  setCompanySelected: Function;
  screenUpdate: Function;
}

const ModalView = ({
  companySelected,
  setCompanySelected,
  screenUpdate,
}: ModalViewProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(!!companySelected ? true : false);
  }, [companySelected]);

  function handleStatus(status: boolean) {
    document.getElementsByTagName("h1")[0].click();
    if (companySelected) {
      let newCompany: Company = { ...companySelected };
      newCompany.is_active = status;
      updateStatusCompany(newCompany).then((response) => {
        if (response.status === statusCode.updated) {
          setCompanySelected(newCompany);
          screenUpdate();
        }
      });
    }
  }

  function handleDelete() {
    document.getElementsByTagName("h1")[0].click();
    if (companySelected?.uuid_company) {
      deleteCompany(companySelected.uuid_company).then((response) => {
        if (response.status === statusCode.updated)
          toast.success(messagesAction.deleted);
        setCompanySelected(undefined);
        screenUpdate();
      });
    }
  }

  return (
    <Modal
      show={openModal}
      onHide={() => setCompanySelected(undefined)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Visualizar loja</Modal.Title>
      </Modal.Header>
      {!!companySelected && (
        <>
          <Modal.Body>
            <p className="mb-0">
              <b>Código: </b>
              {companySelected.code}
            </p>
            <p className="mb-0 mt-3">
              <b>Loja: </b>
              {companySelected.name}
            </p>
            <p className="mb-0 mt-3">
              <b>Endereço: </b>
              {companySelected.address}
              <br />
              Bairro {companySelected.neighborhood} - CEP{" "}
              {companySelected.postal_code}
              <br />
              {companySelected.city?.name}/{companySelected.city?.state.name}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Row className="gx-3">
              <Col xs="auto">
                {companySelected.is_active ? (
                  <BtnDropdown
                    btn={messagesAction.inactive}
                    icon={faBan}
                    title={messagesAction.inactive}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.inactive +
                      " a loja? Os vendedores da mesma não terão mais acesso ao sistema."
                    }
                    variant_color="warning"
                    handleAction={() => handleStatus(false)}
                  />
                ) : (
                  <BtnDropdown
                    btn={messagesAction.active}
                    icon={faCheck}
                    title={messagesAction.active}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.active +
                      " a loja?"
                    }
                    variant_color="success"
                    handleAction={() => handleStatus(true)}
                  />
                )}
              </Col>
              <Col xs="auto">
                <BtnDropdown
                  btn={messagesAction.delete}
                  icon={faTrash}
                  title={messagesAction.delete}
                  msg={
                    "Tem certeza que deseja " +
                    messagesAction.delete +
                    " a loja? Essa ação não pode ser desfeita."
                  }
                  variant_color="danger"
                  handleAction={handleDelete}
                />
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalView;
