interface AvatarProps {
  src?: string;
  big?: boolean;
  name?: string;
  showName?: boolean;
}

const Avatar = ({ src, big, name, showName }: AvatarProps) => (
  <>
    <img
      src={
        src
          ? src
          : "https://ui-avatars.com/api/?background=2BB4D3&color=fff" +
            (big ? "&size=100" : "") +
            "&name=" +
            name
      }
      className={(big ? "avatar-big" : "avatar") + " img-fluid rounded-circle"}
      alt={name}
    />
    {!!showName && <span className="ps-2 text-dark">{name}</span>}
  </>
);

export default Avatar;
