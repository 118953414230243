import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

import Content from "../components/Content";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import adminItems from "../components/sidebar/adminItems";
import clientItems from "../components/sidebar/clientItems";
import Sidebar from "../components/sidebar/Sidebar";
import Wrapper from "../components/Wrapper";
import useToken from "../utils/hooks/useToken";

const Dashboard = ({ children }: PropsWithChildren) => {
  const { isAdmin } = useToken();

  return (
    <Wrapper>
      <Sidebar items={isAdmin ? adminItems : clientItems} />
      <Main>
        <Navbar />
        <Content>
          {children}
          <Outlet />
        </Content>
        <Footer />
      </Main>
    </Wrapper>
  );
};

export default Dashboard;
