import { Col } from "react-bootstrap";

interface FilterProps {
  verifyFilters: Function;
  showAll?: boolean;
}

const FilterActive = ({ verifyFilters, showAll }: FilterProps) => (
  <Col sm="auto" className="mt-3">
    <label className="form-label">Ativo?</label>
    <select
      className="form-control form-select input-filter"
      onChange={(e) => verifyFilters("is_active", e.target.value)}
    >
      {!!showAll && <option value="">Todos</option>}
      <option value="true">Sim</option>
      <option value="false">Não</option>
    </select>
  </Col>
);

export default FilterActive;
