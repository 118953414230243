import { AxiosResponse } from "axios";
import fileDownload from 'js-file-download';
import { toast } from "react-toastify";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { Sale, SaleList } from "../interfaces/sale";

export async function getSaleAll(filters?: any): Promise<SaleList> {
  return axiosInstance.get('/sale/?' + new URLSearchParams(filters).toString()).then(response =>
    verifyDataPagination(response)
  ).catch(error => verifyDataPagination(error));
}

export async function postSale(saleData: any): Promise<AxiosResponse> {
  return axiosInstance.post('/sale/', saleData).then(response =>
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function getSale(uuid: string): Promise<Sale | undefined> {
  return axiosInstance.get('/sale/' + uuid).then(response =>
    verifyData(response)
  ).catch(error => verifyData(error));
}

export async function updateSale(saleData: Sale): Promise<AxiosResponse> {
  return axiosInstance.patch('/sale/', saleData).then(response =>
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function deleteSale(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/sale/' + uuid).then(response =>
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function downloadFileSale(filename: string): Promise<void> {
  axiosInstance.get('/uploads/nota/' + filename, { responseType: 'blob' }).then((response) => {
    try {
      fileDownload(response.data, filename);
    } catch (error: any) {
      console.log(error);
    }
  }
  ).catch(error => verifyResponse(error));
}

export async function downloadReportSaleByUser(user: string): Promise<void> {
  axiosInstance.get('/sale/reportSalesByUser/' + user, { responseType: 'blob' }).then((response) => {
    try {
      fileDownload(response.data, 'relatorio-' + user + '.zip');
    } catch (error: any) {
      console.log(error);
    }
  }
  ).catch(error => verifyResponse(error));
}