import { AxiosResponse } from "axios";

import { statusCode } from "../../../beans/enumerators";
import axiosInstance from "../../../utils/axios";
import { verifyResponse } from "../../../utils/verify-response";
import { SignInForm } from "../interfaces/auth";

export async function signInApi(userData: SignInForm): Promise<AxiosResponse> {
	return axiosInstance.post('/login/', userData).then(response => {
		if (typeof response.data === 'string') {
			return {
				status: statusCode.ok,
				statusText: response.data,
				data: {},
				headers: {},
				config: {}
			};
		}
		return verifyResponse(response);
	}).catch(error => verifyResponse(error));
}

export async function resetPasswordApi(email: string): Promise<AxiosResponse> {
	return axiosInstance.put('/recover-password/' + email).then(response => 
		verifyResponse(response)
	).catch(error => verifyResponse(error));
}

export async function newPasswordApi(hash: string, password: string): Promise<AxiosResponse> {
	return axiosInstance.put('/recover-password/change/', { "uuid_recover_password" : hash, password }).then(response => 
		verifyResponse(response)
	).catch(error => verifyResponse(error));
}