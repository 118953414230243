import { useLocation } from "react-router-dom";

import reduceChildRoutes from "./reduceChildRoutes";
import { SidebarItemsType } from "./interfaces/sidebar";

interface SidebarNavListProps {
  depth: number;
  pages: SidebarItemsType[];
}

const SidebarNavList = ({ pages, depth }: SidebarNavListProps) => {
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    [] as JSX.Element[]
  );

  return <>{childRoutes}</>;
};

export default SidebarNavList;
