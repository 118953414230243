import { Formik } from "formik";
import { ChangeEvent, useState } from "react";
import { Helmet } from "react-helmet-async";

import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import Header from "../../components/lists/Header";
import { SaleForm } from "./interfaces/sale";
import Yup from "../error/Yup";
import { messagesAction, messagesForm } from "../../beans/messages";
import { toast } from "react-toastify";
import { postSale } from "./services/sale";
import { statusCode } from "../../beans/enumerators";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MultiValue } from "react-select";
import AsyncSelect from "react-select/async";
import { getProductAll } from "../product/services/product";

interface ProductOptionType {
  value?: string;
  label?: string;
}

const SaleCreate = () => {
  const initialValues: SaleForm = {
    submit: false,
  };
  const [sendFile, setSendFile] = useState<File | null>();
  function handleFile(file: FileList | null) {
    setSendFile(file ? file[0] : null);
  }
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<
    MultiValue<ProductOptionType | null>
  >([]);

  const filterProduct = (inputValue: string) => {
    return getProductAll({ name: inputValue, is_active: true }).then(
      (response) => {
        let newOptions: ProductOptionType[] = [];
        if (response.list)
          newOptions = response.list.map((product) => ({
            value: product.uuid_product,
            label: product.name + " (" + product.loyalty_points + " pontos)",
          }));
        return newOptions;
      }
    );
  };
  let timeout: ReturnType<typeof setTimeout>;
  const loadOptions = (
    inputValue: string,
    callback: (options: ProductOptionType[]) => void
  ) => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      callback(await filterProduct(inputValue));
    }, 1000);
  };

  const handleChangeSelect = (
    selected: MultiValue<ProductOptionType | null>
  ) => {
    setSelectedOptions((prev) => [...prev, ...selected]);
  };

  const removeOption = (indexToRemove: number) => {
    setSelectedOptions((prev) =>
      prev.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <>
      <Helmet title="Adicionar venda" />
      <Container fluid className="p-0">
        <Header title="Adicionar venda" />
        <Card className="p-3">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              date: Yup.string().required(messagesForm.required),
            })}
            onSubmit={async (
              values,
              { setStatus, setErrors, setSubmitting }
            ) => {
              if (!sendFile || !selectedOptions.length) {
                setErrors({
                  submit: "Todos os campos são obrigatórios",
                });
                setStatus({ success: false });
                setSubmitting(false);
              } else {
                values.list_products = selectedOptions.map((item) =>
                  item && item.value ? item.value : ""
                );
                var saleData = new FormData();
                saleData.append("file", sendFile);
                saleData.append("data", JSON.stringify(values));
                console.log(values);
                await postSale(saleData).then((response) => {
                  if (response.status !== statusCode.created) {
                    setStatus({ success: false });
                    setSubmitting(false);
                  } else {
                    toast.success(messagesAction.created);
                    navigate("/vendas/");
                  }
                });
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col xs="auto">
                    <Form.Group className="mb-3">
                      <Form.Label>Data da venda</Form.Label>
                      <Form.Control
                        size="lg"
                        type="date"
                        name="date"
                        value={values.date}
                        max={moment().format("YYYY-MM-DD")}
                        isInvalid={Boolean(touched.date && errors.date)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.date && (
                        <Form.Control.Feedback type="invalid">
                          {errors.date}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs="auto">
                    <Form.Group className="mb-3">
                      <Form.Label>Nota fiscal</Form.Label>
                      <Form.Control
                        size="lg"
                        type="file"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleFile(e.target.files)
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>Produtos</Form.Label>
                      <AsyncSelect
                        isMulti
                        value={null}
                        loadOptions={loadOptions}
                        onChange={handleChangeSelect}
                        placeholder="Digite para buscar"
                        noOptionsMessage={() => "Nada encontrado"}
                        loadingMessage={() => "Buscando produtos..."}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            danger: "#d9534f",
                            primary: "#001A70",
                          },
                        })}
                      />
                    </Form.Group>
                    {selectedOptions.map((opt, index) => (
                      <div className="mb-2 d-flex align-items-center">
                        <span>{opt ? opt.label : ""}</span>
                        <button
                          type="button"
                          className="btn-close btn-sm ms-2"
                          aria-label="Remove"
                          onClick={() => removeOption(index)}
                        />
                      </div>
                    ))}
                  </Col>
                </Row>

                {!!errors.submit && (
                  <Alert className="mt-3" variant="danger">
                    <div className="alert-message">
                      {errors.submit?.toString()}
                    </div>
                  </Alert>
                )}

                <div className="mt-3">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {messagesAction.save}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </>
  );
};

export default SaleCreate;
