import { Formik } from "formik";
import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import InputMask from "react-input-mask";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import { UserForm } from "../../user/interfaces/user";
import { postUser } from "../../user/services/user";
import SelectState from "../../company/components/SelectState";
import SelectCity from "../../company/components/SelectCity";
import SelectCompany from "../../company/components/SelectCompany";

const SignUp = () => {
  const [sent, setSent] = useState(false);
  const initialValues: UserForm = {
    submit: false,
    accept_link: false,
    accept_msg: false,
  };
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  return sent ? (
    <Alert variant="success">
      <div className="alert-message">
        Conta criada com sucesso, <Link to="/acesso/entrar/">acesse aqui</Link>.
      </div>
    </Alert>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(150).required(messagesForm.required),
        phone: Yup.string().max(16).required(messagesForm.required),
        login: Yup.object().shape({
          email: Yup.string()
            .email(messagesForm.invalid)
            .max(100)
            .required(messagesForm.required),
          password: Yup.string().max(50).required(messagesForm.required),
          confirm_password: Yup.string()
            .max(50)
            .required(messagesForm.required)
            .oneOf([Yup.ref("password"), null], messagesForm.password),
        }),
      })}
      onSubmit={async (values, { setStatus, setSubmitting, setErrors }) => {
        if (!selectedCompany || !values.accept_link || !values.accept_msg) {
          setStatus({ success: false });
          setErrors({
            submit: "Todos os campos são obrigatórios",
          });
          setSubmitting(false);
        } else {
          values.company = { uuid_company: selectedCompany };
          if (values.phone) {
            let getPhone = values.phone.replace(/\D/g, "");
            values.phone = getPhone;
          }
          var userData = new FormData();
          userData.append("data", JSON.stringify(values));

          await postUser(userData).then((response) => {
            if (response.status !== statusCode.created) {
              setStatus({ success: false });
              setSubmitting(false);
            } else {
              setSent(true);
            }
          });
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Nome completo</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={values.name}
              isInvalid={Boolean(touched.name && errors.name)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              type="email"
              name="login.email"
              value={values.login?.email}
              isInvalid={Boolean(
                //@ts-ignore
                touched.login?.email && errors.login?.email
              )}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {
              //@ts-ignore
              !!touched.login?.email && (
                <Form.Control.Feedback type="invalid">
                  {
                    //@ts-ignore
                    errors.login?.email
                  }
                </Form.Control.Feedback>
              )
            }
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Telefone / Whatsapp</Form.Label>
            <Form.Control
              as={InputMask}
              mask="(99) 9 9999-9999"
              maskPlaceholder={null}
              type="tel"
              name="phone"
              value={values.phone?.toString()}
              isInvalid={Boolean(touched.phone && errors.phone)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.phone && (
              <Form.Control.Feedback type="invalid">
                {errors.phone}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Row className="gx-2">
            <Col md="5">
              <Form.Group className="mb-3">
                <SelectState
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  selectAll={true}
                />
              </Form.Group>
            </Col>
            <Col md="7">
              <Form.Group className="mb-3">
                <SelectCity
                  selectedState={selectedState}
                  selectedCity={selectedCity}
                  setSelectedCity={setSelectedCity}
                  selectAll={true}
                />
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group className="mb-3">
                <SelectCompany
                  selectedCity={selectedCity}
                  selectedCompany={selectedCompany}
                  setSelectedCompany={setSelectedCompany}
                  selectAll={true}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  type="password"
                  name="login.password"
                  value={values.login?.password}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.login?.password && errors.login?.password
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.login?.password && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.login?.password
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>Confirmar senha</Form.Label>
                <Form.Control
                  type="password"
                  name="login.confirm_password"
                  value={values.login?.confirm_password}
                  isInvalid={Boolean(
                    //@ts-ignore
                    touched.login?.confirm_password &&
                      //@ts-ignore
                      errors.login?.confirm_password
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {
                  //@ts-ignore
                  !!touched.login?.confirm_password && (
                    <Form.Control.Feedback type="invalid">
                      {
                        //@ts-ignore
                        errors.login?.confirm_password
                      }
                    </Form.Control.Feedback>
                  )
                }
              </Form.Group>
            </Col>
            <Col sm={12}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="accept_link"
                  id="accept_link"
                  defaultChecked={false}
                  onChange={(e) =>
                    setFieldValue("accept_link", e.target.checked)
                  }
                  label={
                    <>
                      Li e aceito os{" "}
                      <a
                        href={"#"}
                        target="_blank"
                        rel="noreferrer"
                        className="text-muted"
                      >
                        Termos de Uso
                      </a>
                      {" e "}
                      <a
                        href="https://fluidra.com.br/politica-de-privacidade-e-lgpd"
                        target="_blank"
                        rel="noreferrer"
                        className="text-muted"
                      >
                        Política de Privacidade
                      </a>
                    </>
                  }
                />
                <Form.Check
                  type="checkbox"
                  name="accept_msg"
                  id="accept_msg"
                  defaultChecked={false}
                  onChange={(e) =>
                    setFieldValue("accept_msg", e.target.checked)
                  }
                  label="Aceito receber mensagens da Fluidra"
                />
              </Form.Group>
            </Col>
          </Row>

          {!!errors.submit && (
            <Alert className="mt-3" variant="danger">
              <div className="alert-message">{errors.submit?.toString()}</div>
            </Alert>
          )}

          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.create}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
