import { AxiosResponse } from "axios";
import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataList, verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { City, Company, CompanyAll, State } from "../interfaces/company";

export async function getCompanyAll(filters?: any): Promise<CompanyAll> {
  return axiosInstance.get('/company/?' + new URLSearchParams(filters).toString()).then(response => 
    verifyDataPagination(response)
  ).catch(error => verifyDataPagination(error));
}

export async function getStateAll(): Promise<Array<State>> {
  return axiosInstance.get('/state/').then(response => 
    verifyDataList(response)
  ).catch(error => verifyDataList(error));
}

export async function getCityAll(id_state: string): Promise<Array<City>> {
  return axiosInstance.get('/city/allbystate/' + id_state).then(response => 
    verifyDataList(response)
  ).catch(error => verifyDataList(error));
}

export async function importCompany(file: any): Promise<AxiosResponse> {
  return axiosInstance.post('/company/', file).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function postCompany(data: Company): Promise<AxiosResponse> {
  return axiosInstance.post('/company/create/', data).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function updateCompany(data: Company): Promise<AxiosResponse> {
  return axiosInstance.put('/company/', data).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function updateStatusCompany(data: Company): Promise<AxiosResponse> {
  return axiosInstance.patch('/company/', data).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function deleteCompany(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/company/' + uuid).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function getCompany(uuid: string): Promise<Company | undefined> {
  return axiosInstance.get('/company/' + uuid).then(response => 
    verifyData(response)
  ).catch(error => verifyData(error));
}