import { faShoppingCart, faUser, faBoxes, faHome } from "@fortawesome/free-solid-svg-icons";

import { SidebarItemsType } from "./interfaces/sidebar";

const homeSection = [
  {
    href: "/",
    icon: faHome,
    title: "Dashboard",
  },
] as SidebarItemsType[];

const accountSection = [
  {
    href: "/usuarios/perfil/",
    icon: faUser,
    title: "Perfil",
  },
] as SidebarItemsType[];

const pagesSection = [
  {
    href: "/vendas",
    icon: faShoppingCart,
    title: "Vendas",
    children: [
      {
        href: "/vendas",
        title: "Ver minhas vendas"
      },
      {
        href: "/vendas/adicionar",
        title: "Adicionar nova"
      }
    ]
  },
  {
    href: "/produtos",
    icon: faBoxes,
    title: "Produtos",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Início",
    pages: homeSection,
  },
  {
    title: "Gerenciar",
    pages: pagesSection,
  },
  {
    title: "Minha conta",
    pages: accountSection,
  },
];

export default navItems;
