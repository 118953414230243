import { Helmet } from "react-helmet-async";

import { Container, Card } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormCompany from "./components/FormCompany";

const CompanyCreate = () => (
  <>
    <Helmet title="Adicionar loja" />
    <Container fluid className="p-0">
      <Header title="Adicionar loja" />
      <Card className="p-3">
        <FormCompany />
      </Card>
    </Container>
  </>
);

export default CompanyCreate;
