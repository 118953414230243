import classNames from "classnames";

import useSidebar from "./sidebar/hooks/useSidebar";

interface MainType {
  className?: string;
  children: React.ReactNode;
}

const Main = ({ className, children }: MainType) => {
  const { isOpen, setIsOpen } = useSidebar();

  return (
    <div
      onClick={() => {
        !isOpen && setIsOpen(true);
      }}
      className={classNames("main", className)}
    >
      {children}
    </div>
  );
};

export default Main;
