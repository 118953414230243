import { Navigate } from "react-router-dom";

import useToken from "../../utils/hooks/useToken";

interface ClientGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by authenticated CLIENT users
function ClientGuard({ children }: ClientGuardType) {
  const { isAdmin } = useToken();

  if (isAdmin) return <Navigate to="/" />;

  return <>{children}</>;
}

export default ClientGuard;
