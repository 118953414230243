import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";

import ResetPassword from "./components/ResetPassword";
import LogoWhite from "./components/LogoWhite";

const ResetPasswordPage = () => (
  <>
    <Helmet title="Recuperar senha" />
    <Row className="h-xl-100 align-items-center py-4 g-0">
      <LogoWhite />
      <Col lg={5}>
        <div className="block-max-width mx-auto mt-5 mt-xl-0">
          <div className="text-center">
            <h2>Recuperar senha</h2>
            <p className="lead">
              Informe seu e-mail para receber a recuperação de senha
            </p>
          </div>
          <ResetPassword />
          <small className="mt-4 d-block">
            <Link to="/acesso/entrar/">Voltar</Link>
          </small>
        </div>
      </Col>
    </Row>
  </>
);

export default ResetPasswordPage;
