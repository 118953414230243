import axios from "axios";

import { getToken } from "./token";

export const baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({ baseURL });

axiosInstance.interceptors.request.use(function (config) {
  config.headers = {
    Authorization: `Bearer ${getToken()}`,
  };
  return config;
}, function (error) {
  return error;
});
axiosInstance.interceptors.response.use(
  response => response,
  error => { throw error.response ? error.response : error }
);

export default axiosInstance;