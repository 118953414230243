import { useEffect, useState } from "react";
import { Col, Row, Collapse, Card } from "react-bootstrap";
import FilterActive from "../../../components/lists/FilterActive";
import SelectState from "../../company/components/SelectState";
import SelectCity from "../../company/components/SelectCity";
import SelectCompany from "../../company/components/SelectCompany";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
}

const Filter = ({ verifyFilters, openFilters }: FilterProps) => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");

  useEffect(() => {
    verifyFilters("id_state", selectedState);
  }, [selectedState]);
  useEffect(() => {
    verifyFilters("id_city", selectedCity);
  }, [selectedCity]);
  useEffect(() => {
    verifyFilters("uuid_company", selectedCompany);
  }, [selectedCompany]);

  return (
    <Collapse in={openFilters} className="mq-filter p-3">
      <Card>
        <Row className="mt-n3">
          <FilterActive verifyFilters={verifyFilters} />
          <Col sm="auto" className="mt-3">
            <div className="input-filter">
              <SelectState
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                showAll={true}
                selectAll={true}
              />
            </div>
          </Col>
          <Col sm="auto" className="mt-3">
            <div className="input-filter">
              <SelectCity
                selectedState={selectedState}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                showAll={true}
                selectAll={true}
              />
            </div>
          </Col>
          <Col sm="auto" className="mt-3">
            <div className="input-filter">
              <SelectCompany
                selectedCity={selectedCity}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                showAll={true}
                selectAll={true}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
