export enum messagesForm {
    required = "Campo obrigatório",
    invalid = "Campo com valor inválido",
    password = "Senhas devem ser iguais",
    error = "Erro inesperado"
};

export enum messagesAction {
    create = "Cadastrar",
    update = "Atualizar",
    send = "Enviar",
    edit = "Editar",
    delete = "Excluir",
    active = "Ativar",
    inactive = "Desativar",
    close = "Fechar",
    save = "Salvar",
    download = "Baixar",
    success = "Dados salvos com sucesso",
    view = "Visualizar",
    deleted = "Registro excluído",
    created = "Registro cadastrado"
};