import { Helmet, HelmetProvider } from "react-helmet-async";
import { useRoutes } from "react-router-dom";

import { SidebarProvider } from "./components/sidebar/contexts/SidebarContext";
import routes from "./routes";
import { TokenProvider } from "./utils/contexts/TokenContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={"%s | " + process.env.REACT_APP_WEBSITE_NAME}
        defaultTitle={process.env.REACT_APP_WEBSITE_NAME}
      />
      <TokenProvider>
        <SidebarProvider>{content}</SidebarProvider>
      </TokenProvider>
      <ToastContainer />
    </HelmetProvider>
  );
};

export default App;
