import { Button, Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface BtnDropdownProps {
  btn: string;
  icon: IconProp;
  title: string;
  msg: string;
  handleAction: (id?: number | string) => void;
  id?: number | string;
  variant_color?: string;
}

const BtnDropdown = ({
  btn,
  icon,
  title,
  msg,
  handleAction,
  id,
  variant_color,
}: BtnDropdownProps) => (
  <Dropdown className="d-inline-block mq-dropdown" align="end">
    <Dropdown.Toggle
      variant={variant_color ? variant_color : "danger"}
      className="ms-1"
    >
      <FontAwesomeIcon icon={icon} />
      <span className="d-none d-xl-inline-block ps-1">{btn}</span>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      <Dropdown.ItemText>{title}</Dropdown.ItemText>
      <Dropdown.Divider></Dropdown.Divider>
      <Dropdown.ItemText>
        <span>{msg}</span>
        <Button
          variant={variant_color ? variant_color : "danger"}
          className="w-100 mt-3"
          onClick={() => handleAction(id)}
        >
          {title}
        </Button>
      </Dropdown.ItemText>
    </Dropdown.Menu>
  </Dropdown>
);

export default BtnDropdown;
