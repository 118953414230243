import { AxiosResponse } from "axios";
import { toast } from "react-toastify";

import { getToken, unsetSession } from './token';

export function verifyDataList(response: any): Array<object> {
    verifyStatusCode(response);
    if (!response || !response.data) return [];
    if (response.data[0]) return response.data;
    return [];
}

interface PaginationResponse {
    list: Array<any>;
    total: number;
}
export function verifyDataPagination(response: any): PaginationResponse {
    verifyStatusCode(response);
    if (!response || !response.data || !response.data.list) return { list: [], total: 0 };
    if (response.data.list) return response.data;
    return { list: [], total: 0 };
}

export function verifyData(response: any): object | undefined {
    verifyStatusCode(response);
    if (!response || !response.data) return undefined;
    if (typeof response.data === 'object') return response.data;
    return undefined;
}

export function verifyDataArray(response: any): Array<any> | undefined {
    verifyStatusCode(response);
    if (!response || !response.data) return undefined;
    if (typeof response.data === 'object') return response.data;
    return undefined;
}

export function verifyResponse(response: AxiosResponse): AxiosResponse {
    verifyStatusCode(response);
    let responseCheck =  (response.data && response.data.status) ? response.data : response;
    return responseCheck;
}

export async function verifyStatusCode(response: any) {
    if(!response || !response.status) {
        console.log('Erro inesperado na aplicação: ', response);
        toast.error('Erro inesperado');
        return;
    }
    let responseCheck =  (response.data && response.data.status) ? response.data : response;
    if (responseCheck.status >= 400) {
        if (responseCheck.status === 401 && getToken()) {
            unsetSession(); 
        } else {
            let error : any = responseCheck.statusText ? responseCheck.statusText : 'Erro inesperado: ' + responseCheck.status;
            toast.error(typeof error === 'string' ? error : error[0]);
        }
    }
}