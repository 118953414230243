import { Helmet } from "react-helmet-async";

import { Container, Card } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormLogin from "./components/FormUser";

const UserCreate = () => (
  <>
    <Helmet title="Adicionar usuário" />
    <Container fluid className="p-0">
      <Header title="Adicionar usuário" />
      <Card className="p-3">
        <FormLogin />
      </Card>
    </Container>
  </>
);

export default UserCreate;
