import { useEffect, useState } from "react";
import { City } from "../interfaces/company";
import { getCityAll } from "../services/company";

interface SelectCityProps {
  selectedState: string;
  selectedCity: string;
  setSelectedCity: Function;
  showAll?: boolean;
  selectAll?: boolean;
}

const SelectCity = ({
  selectedState,
  selectedCity,
  setSelectedCity,
  showAll,
  selectAll,
}: SelectCityProps) => {
  const [list, setList] = useState<Array<City>>([]);

  function updateCity() {
    if (selectedState)
      getCityAll(selectedState).then((response) => setList(response));
    else setList([]);

    setSelectedCity("");
  }
  useEffect(() => {
    updateCity();
  }, [selectedState]);

  return (
    <>
      <label className="form-label">Cidade</label>
      <select
        className="form-control form-select"
        onChange={(e) => setSelectedCity(e.target.value)}
        value={selectedCity}
      >
        {!!selectAll && (
          <option value="">{showAll ? "Todas" : "Selecione"}</option>
        )}
        {!!list.length ? (
          list.map((item) => <option value={item.id_city}>{item.name}</option>)
        ) : (
          <option value="" disabled>
            Selecione o estado
          </option>
        )}
      </select>
    </>
  );
};

export default SelectCity;
