import { Helmet } from "react-helmet-async";
import { Container } from "react-bootstrap";

import Header from "./components/Header";
import Ranking from "./components/Ranking";

const Dashboard = () => (
  <>
    <Helmet title="Dashboard" />
    <Container fluid className="p-0">
      <Header />
      <Ranking />
    </Container>
  </>
);

export default Dashboard;
