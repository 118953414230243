import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { messagesAction } from "../../beans/messages";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import useToken from "../../utils/hooks/useToken";
import Filter from "./components/Filter";
import { Product } from "./interfaces/product";
import { getProductAll } from "./services/product";
import { itens_per_page } from "../../beans/enumerators";
import ModalImport from "./components/ModalImport";
import ModalView from "./components/ModalView";
import { Link } from "react-router-dom";

const ProductList = () => {
  const { isAdmin } = useToken();

  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<Product>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    is_active: "true",
    name: "",
    page: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "page") newFilters["page"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    getProductAll(filters).then((response) => {
      setList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [productSelected, setProductSelected] = useState<Product>();

  return (
    <>
      <Helmet title={"Produtos" + (isAdmin ? "" : " participantes")} />
      <Container fluid className="p-0">
        <Header
          title={"Produtos" + (isAdmin ? "" : " participantes")}
          verifyFilters={verifyFilters}
          openFilters={openFilters}
          setOpenFilters={isAdmin ? setOpenFilters : undefined}
          setOpenModal={isAdmin ? setOpenModal : undefined}
        />
        {isAdmin && (
          <Filter openFilters={openFilters} verifyFilters={verifyFilters} />
        )}
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <p className="d-md-none pt-3 ps-3">
            <b>Lista de produtos</b>
          </p>
          <Table striped className="mq-table-big">
            <thead>
              <tr>
                <th className="col-md-5">Produto</th>
                <th>Pontos</th>
                {isAdmin && <th></th>}
              </tr>
            </thead>
            <tbody>
              {!!list &&
                list.map((item, key) => (
                  <tr key={key}>
                    <td>{item.name}</td>
                    <td>
                      {item.is_active ? (
                        item.loyalty_points + " pontos"
                      ) : (
                        <Badge bg="danger">Inativo</Badge>
                      )}
                    </td>
                    {isAdmin && (
                      <td className="text-md-end mq-actions">
                        <Link to={"/produtos/" + item.uuid_product}>
                          <Button variant="primary">
                            <FontAwesomeIcon icon={faPen} />
                            <span className="ps-1">{messagesAction.edit}</span>
                          </Button>
                        </Link>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.page}
          verifyFilters={verifyFilters}
        />
      </Container>
      {isAdmin && (
        <>
          <ModalImport
            openModal={openModal}
            setOpenModal={setOpenModal}
            screenUpdate={screenUpdate}
          />
          <ModalView
            productSelected={productSelected}
            setProductSelected={setProductSelected}
            screenUpdate={screenUpdate}
          />
        </>
      )}
    </>
  );
};

export default ProductList;
