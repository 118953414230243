import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";

import { Container, Card, Col } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormLogin from "./components/FormUser";

import { UserDetail } from "./interfaces/user";
import { deleteUser, getUser, updateUser } from "./services/user";

import useToken from "../../utils/hooks/useToken";
import { account_type, statusCode } from "../../beans/enumerators";
import BtnDropdown from "../../components/lists/BtnDropdown";
import { messagesAction } from "../../beans/messages";
import { faBan, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const UserUpdate = () => {
  const { user } = useToken();
  const [userSelected, setUserSelected] = useState<UserDetail>();
  const navigate = useNavigate();
  const { id } = useParams();

  function profile() {
    navigate("/usuarios/" + user?.user.uuid_user + "/");
  }

  function goBack() {
    navigate("/usuarios/");
  }

  useEffect(() => {
    if (id) {
      if (id === "perfil") {
        profile();
      } else if (
        user?.account_type === account_type.client &&
        user.user.uuid_user !== id
      ) {
        goBack();
      } else {
        getUser(id).then((response) => {
          if (response) {
            setUserSelected(response);
          } else {
            goBack();
          }
        });
      }
    } else {
      goBack();
    }
  }, [id]);

  function handleStatus(status: boolean) {
    document.getElementsByTagName("h1")[0].click();
    if (userSelected) {
      let newUser: UserDetail = { ...userSelected };
      if (newUser.login) newUser.login.is_active = status;
      var userData = new FormData();
      userData.append("data", JSON.stringify(newUser));
      updateUser(userData).then((response) => {
        if (response.status === statusCode.updated) setUserSelected(newUser);
      });
    }
  }

  function handleDelete() {
    document.getElementsByTagName("h1")[0].click();
    if (userSelected?.uuid_user) {
      deleteUser(userSelected.uuid_user).then((response) => {
        if (response.status === statusCode.updated) {
          toast.success(messagesAction.deleted);
          goBack();
        }
      });
    }
  }

  return (
    <>
      <Helmet title={"Editar usuário: " + userSelected?.name} />
      <Container fluid className="p-0">
        <Header title={"Editar usuário: " + userSelected?.name}>
          {user?.user.uuid_user !== id && (
            <>
              <Col xs="auto">
                {userSelected?.login?.is_active ? (
                  <BtnDropdown
                    btn={messagesAction.inactive}
                    icon={faBan}
                    title={messagesAction.inactive}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.inactive +
                      " o usuário? O mesmo não terá mais acesso ao sistema."
                    }
                    variant_color="warning"
                    handleAction={() => handleStatus(false)}
                  />
                ) : (
                  <BtnDropdown
                    btn={messagesAction.active}
                    icon={faCheck}
                    title={messagesAction.active}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.active +
                      " o usuário? O mesmo terá acesso ao sistema."
                    }
                    variant_color="success"
                    handleAction={() => handleStatus(true)}
                  />
                )}
              </Col>
              <Col xs="auto">
                <BtnDropdown
                  btn={messagesAction.delete}
                  icon={faTrash}
                  title={messagesAction.delete}
                  msg={
                    "Tem certeza que deseja " +
                    messagesAction.delete +
                    " o usuário? Essa ação não pode ser desfeita."
                  }
                  variant_color="danger"
                  handleAction={handleDelete}
                />
              </Col>
            </>
          )}
        </Header>
        <Card className="p-3">
          <FormLogin
            userSelected={userSelected}
            setUserSelected={setUserSelected}
          />
        </Card>
      </Container>
    </>
  );
};

export default UserUpdate;
