import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyDataPagination, verifyResponse } from "../../../utils/verify-response";
import { UserDetailList, UserDetail } from "../interfaces/user";

export async function getUserAll(filters?: any): Promise<UserDetailList> {
  return axiosInstance.get('/user/?' + new URLSearchParams(filters).toString()).then(response => 
    verifyDataPagination(response)
  ).catch(error => verifyDataPagination(error));
}

export async function postUser(userData: any): Promise<AxiosResponse> {
  return axiosInstance.post('/user/', userData).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function getUser(uuid: string): Promise<UserDetail | undefined> {
  return axiosInstance.get('/user/' + uuid).then(response => 
    verifyData(response)
  ).catch(error => verifyData(error));
}

export async function updateUser(userData: any): Promise<AxiosResponse> {
  return axiosInstance.put('/user/', userData).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}

export async function deleteUser(uuid: string): Promise<AxiosResponse> {
  return axiosInstance.delete('/user/' + uuid).then(response => 
    verifyResponse(response)
  ).catch(error => verifyResponse(error));
}