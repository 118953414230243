import { Col } from "react-bootstrap";
import banner from "../../../assets/img/banner.jpg";

const LogoWhite = () => (
  <Col xl={7} className="h-xl-100">
    <div className="d-flex h-100 align-items-center justify-content-center">
      <img src={banner} alt="Banner" className="w-100 rounded-lg" />
    </div>
  </Col>
);

export default LogoWhite;
