import { useEffect, useState } from "react";
import { Card, Col, Collapse, Row } from "react-bootstrap";

import FilterActive from "../../../components/lists/FilterActive";
import SelectCity from "../../company/components/SelectCity";
import SelectCompany from "../../company/components/SelectCompany";
import SelectState from "../../company/components/SelectState";
import SelectUser from "./SelectUser";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
  isAdmin: boolean;
}

const Filter = ({ verifyFilters, openFilters, isAdmin }: FilterProps) => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<string>("");

  useEffect(() => {
    verifyFilters("id_state", selectedState);
  }, [selectedState]);
  useEffect(() => {
    verifyFilters("id_city", selectedCity);
  }, [selectedCity]);
  useEffect(() => {
    verifyFilters("uuid_company", selectedCompany);
  }, [selectedCompany]);
  useEffect(() => {
    verifyFilters("uuid_user", selectedUser);
  }, [selectedUser]);

  return (
    <Collapse in={openFilters} className="mq-filter p-3">
      <Card>
        <Row className="mt-n3">
          <FilterActive verifyFilters={verifyFilters} showAll={!isAdmin} />
          {isAdmin && (
            <>
              <Col sm="auto" className="mt-3">
                <div className="input-filter">
                  <SelectState
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    showAll={true}
                    selectAll={true}
                  />
                </div>
              </Col>
              <Col sm="auto" className="mt-3">
                <div className="input-filter">
                  <SelectCity
                    selectedState={selectedState}
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    showAll={true}
                    selectAll={true}
                  />
                </div>
              </Col>
              <Col sm="auto" className="mt-3">
                <div className="input-filter">
                  <SelectCompany
                    selectedCity={selectedCity}
                    selectedCompany={selectedCompany}
                    setSelectedCompany={setSelectedCompany}
                    showAll={true}
                    selectAll={true}
                  />
                </div>
              </Col>
              <Col sm="auto" className="mt-3">
                <div className="input-filter">
                  <SelectUser
                    selectedCompany={selectedCompany}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                  />
                </div>
              </Col>
            </>
          )}
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
