import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Modal } from "react-bootstrap";

import { messagesAction } from "../../../beans/messages";
import { downloadReportSaleByUser } from "../services/sale";
import { useEffect, useState } from "react";

interface ModalViewProps {
  openModal: boolean;
  setOpenModal: Function;
  userSelected: string | undefined;
}

const ModalSelectReport = ({
  openModal,
  setOpenModal,
  userSelected,
}: ModalViewProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  function handleDownload() {
    setIsDownloading(true);
    if (userSelected) downloadReportSaleByUser(userSelected);
  }

  useEffect(() => {
    if (!openModal) setIsDownloading(false);
  }, [openModal]);

  return (
    <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Escolha o relatório</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <Button
            variant="primary me-3"
            size="lg"
            disabled={!userSelected || isDownloading}
            onClick={handleDownload}
          >
            <FontAwesomeIcon icon={faDownload} />
            <span className="ps-2">{messagesAction.download}</span>
          </Button>
          <p className="mb-0">Vendas por vendedor com notas</p>
        </div>
        {!userSelected && (
          <Alert className="mt-3" variant="danger">
            <div className="alert-message">
              É obrigatório selecionar um vendedor em "Filtros" para exportar.
            </div>
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ModalSelectReport;
