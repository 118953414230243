import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { messagesAction } from "../../../beans/messages";
import { statusCode } from "../../../beans/enumerators";
import { importCompany } from "../services/company";

interface ModalImportProps {
  openModal: boolean;
  setOpenModal: Function;
  screenUpdate: Function;
}

const ModalImport = ({
  openModal,
  setOpenModal,
  screenUpdate,
}: ModalImportProps) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [sendFile, setSendFile] = useState<File | null>();
  function handleFile(file: FileList | null) {
    setSendFile(file ? file[0] : null);
  }

  function sendImport() {
    if (sendFile) {
      setIsSending(true);
      var fileData = new FormData();
      fileData.append("xls-file", sendFile);
      importCompany(fileData).then((response) => {
        if (response.status === statusCode.created) {
          toast.success(response.statusText);
          setOpenModal(false);
          screenUpdate();
        } else {
          setIsSending(false);
        }
      });
    }
  }

  useEffect(() => {
    if (!openModal) {
      setSendFile(null);
      setIsSending(false);
    }
  }, [openModal]);

  return (
    <Modal show={openModal} onHide={() => setOpenModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Importar lojas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="lg"
          type="file"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFile(e.target.files)
          }
        />
        <p className="my-3 text-muted">
          * O arquivo de planilha XLSX deve seguir o modelo pré-estabelecido;
          <br />
          * Cadastra novas lojas;
          <br />
          * Atualiza lojas existentes;
          <br />
          * Desativa lojas não presentes no arquivo;
          <br />
        </p>
        <Button
          variant="primary"
          size="lg"
          disabled={!sendFile || isSending}
          onClick={sendImport}
        >
          {messagesAction.send}
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalImport;
