import { getAddressByCEP } from "cep-address-finder";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import Yup from "../../error/Yup";
import { CompanyForm, Company } from "../interfaces/company";
import { getCompany, postCompany, updateCompany } from "../services/company";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import { formatCityName } from "../../../utils/format";

interface CompanyFormProps {
  companySelected?: Company;
  setCompanySelected?: Function;
}

const CompanyFormData = ({
  companySelected,
  setCompanySelected,
}: CompanyFormProps) => {
  const [initialValues, setInitialValues] = useState<CompanyForm>({
    submit: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (companySelected)
      setInitialValues({
        submit: false,
        ...companySelected,
      });
  }, [companySelected]);

  function handleAddress(value: string, setValue: Function) {
    console.log(value);
    setValue("postal_code", value);
    if (value?.length === 8) {
      getAddressByCEP(value)
        .then((response) => {
          console.log(response);
          if (response) {
            setValue("address", response.street);
            setValue("neighborhood", response.neighborhood);
            setValue("city.state.name", response.state);
            setValue("city.name", formatCityName(response.city));
          }
        })
        .catch(() => {
          setValue("postal_code", "");
        });
    } else {
      setValue("address", "");
      setValue("neighborhood", "");
      setValue("city.state.name", "");
      setValue("city.name", "");
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(150).required(messagesForm.required),
        code: Yup.string().max(20).required(messagesForm.required),
        address: Yup.string().max(200).required(messagesForm.required),
        neighborhood: Yup.string().max(100).required(messagesForm.required),
        postal_code: Yup.string()
          .min(8, "Informe um CEP válido")
          .required("Informe um CEP válido"),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        let response = !!companySelected
          ? await updateCompany(values)
          : await postCompany(values);
        if (
          response.status !== statusCode.updated &&
          response.status !== statusCode.created
        ) {
          setStatus({ success: false });
          setSubmitting(false);
        } else {
          if (
            !!companySelected &&
            !!setCompanySelected &&
            values.uuid_company
          ) {
            toast.success(messagesAction.success);
            getCompany(values.uuid_company).then((response) =>
              setCompanySelected(response)
            );
          } else {
            toast.success(messagesAction.created);
            navigate("/lojas/");
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="gx-2">
            <Col xl="4" md="6">
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="name"
                  value={values.name}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Código</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="code"
                  value={values.code}
                  isInvalid={Boolean(touched.code && errors.code)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.code && (
                  <Form.Control.Feedback type="invalid">
                    {errors.code}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>CEP</Form.Label>
                <Form.Control
                  as={InputMask}
                  mask={"99999999"}
                  maskPlaceholder={null}
                  size="lg"
                  type="tel"
                  name="postal_code"
                  value={values.postal_code}
                  isInvalid={Boolean(touched.postal_code && errors.postal_code)}
                  onChange={(e) => handleAddress(e.target.value, setFieldValue)}
                />
                {!!touched.postal_code && (
                  <Form.Control.Feedback type="invalid">
                    {errors.postal_code}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col sm="12"></Col>
            <Col xl="4" md="6" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Rua</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="address"
                  value={values.address}
                  isInvalid={Boolean(touched.address && errors.address)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.address && (
                  <Form.Control.Feedback type="invalid">
                    {errors.address}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="neighborhood"
                  value={values.neighborhood}
                  isInvalid={Boolean(
                    touched.neighborhood && errors.neighborhood
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.neighborhood && (
                  <Form.Control.Feedback type="invalid">
                    {errors.neighborhood}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="city.state.name"
                  value={values.city?.state.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={true}
                />
              </Form.Group>
            </Col>
            <Col xl="3" md="4" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="city.name"
                  value={values.city?.name}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-1">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyFormData;
