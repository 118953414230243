import { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { messagesAction } from "../../../beans/messages";
import { statusCode } from "../../../beans/enumerators";
import BtnDropdown from "../../../components/lists/BtnDropdown";
import {
  faBan,
  faCheck,
  faDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Sale } from "../interfaces/sale";
import { deleteSale, downloadFileSale, updateSale } from "../services/sale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

interface ModalViewProps {
  saleSelected: Sale | undefined;
  setSaleSelected: Function;
  screenUpdate: Function;
  isAdmin: boolean;
}

const ModalView = ({
  saleSelected,
  setSaleSelected,
  screenUpdate,
  isAdmin,
}: ModalViewProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(!!saleSelected ? true : false);
  }, [saleSelected]);

  function handleStatus(status: boolean) {
    document.getElementsByTagName("h1")[0].click();
    if (saleSelected) {
      let newSale: Sale = { ...saleSelected };
      newSale.is_active = status;
      updateSale(newSale).then((response) => {
        if (response.status === statusCode.updated) {
          setSaleSelected(newSale);
          screenUpdate();
        }
      });
    }
  }

  function handleDelete() {
    document.getElementsByTagName("h1")[0].click();
    if (saleSelected?.uuid_sale) {
      deleteSale(saleSelected.uuid_sale).then((response) => {
        if (response.status === statusCode.updated)
          toast.success(messagesAction.deleted);
        setSaleSelected(undefined);
        screenUpdate();
      });
    }
  }

  function handleDownload() {
    if (saleSelected?.fileName) downloadFileSale(saleSelected.fileName);
  }

  return (
    <Modal show={openModal} onHide={() => setSaleSelected(undefined)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Visualizar venda</Modal.Title>
      </Modal.Header>
      {!!saleSelected && (
        <>
          <Modal.Body>
            {!!isAdmin && (
              <p className="mb-3">
                <b>Vendedor: </b>
                {saleSelected.user?.name}
              </p>
            )}
            <p className="mb-0">
              <b>Data da venda: </b>
              {saleSelected.date
                ? moment(saleSelected.date).format("DD/MM/YYYY")
                : ""}
            </p>
            <p className="mb-0 mt-3">
              <b>Produtos: </b>
              {!!saleSelected.saleProducts &&
                saleSelected.saleProducts.map((item) => (
                  <>
                    <br />
                    {item.product.name} ({item.recorded_loyalty_points} pontos)
                  </>
                ))}
            </p>
            <p className="mb-0 mt-3">
              <b>Pontos total: </b>
              {saleSelected.saleProducts?.reduce(
                (total, product) => total + product.recorded_loyalty_points,
                0
              )}
            </p>
            <p className="mb-0 mt-3">
              <Button variant="primary" onClick={handleDownload}>
                <FontAwesomeIcon icon={faDownload} />
                <span className="ps-2">
                  {messagesAction.download} nota fiscal
                </span>
              </Button>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Row className="gx-3">
              {isAdmin && (
                <Col xs="auto">
                  {saleSelected.is_active ? (
                    <BtnDropdown
                      btn={messagesAction.inactive}
                      icon={faBan}
                      title={messagesAction.inactive}
                      msg={
                        "Tem certeza que deseja " +
                        messagesAction.inactive +
                        " a venda? Os pontos serão descontados do vendedor."
                      }
                      variant_color="warning"
                      handleAction={() => handleStatus(false)}
                    />
                  ) : (
                    <BtnDropdown
                      btn={messagesAction.active}
                      icon={faCheck}
                      title={messagesAction.active}
                      msg={
                        "Tem certeza que deseja " +
                        messagesAction.active +
                        " a venda? Os pontos serão somados para o vendedor."
                      }
                      variant_color="success"
                      handleAction={() => handleStatus(true)}
                    />
                  )}
                </Col>
              )}
              <Col xs="auto">
                <BtnDropdown
                  btn={messagesAction.delete}
                  icon={faTrash}
                  title={messagesAction.delete}
                  msg={
                    "Tem certeza que deseja " +
                    messagesAction.delete +
                    " a venda? Essa ação não pode ser desfeita."
                  }
                  variant_color="danger"
                  handleAction={handleDelete}
                />
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalView;
