import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { messagesAction } from "../../../beans/messages";
import { deleteProduct, updateStatusProduct } from "../services/product";
import { statusCode } from "../../../beans/enumerators";
import { Product } from "../interfaces/product";
import BtnDropdown from "../../../components/lists/BtnDropdown";
import { faBan, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

interface ModalViewProps {
  productSelected: Product | undefined;
  setProductSelected: Function;
  screenUpdate: Function;
}

const ModalView = ({
  productSelected,
  setProductSelected,
  screenUpdate,
}: ModalViewProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(!!productSelected ? true : false);
  }, [productSelected]);

  function handleStatus(status: boolean) {
    document.getElementsByTagName("h1")[0].click();
    if (productSelected) {
      let newProduct: Product = { ...productSelected };
      newProduct.is_active = status;
      updateStatusProduct(newProduct).then((response) => {
        if (response.status === statusCode.updated) {
          setProductSelected(newProduct);
          screenUpdate();
        }
      });
    }
  }

  function handleDelete() {
    document.getElementsByTagName("h1")[0].click();
    if (productSelected?.uuid_product) {
      deleteProduct(productSelected.uuid_product).then((response) => {
        if (response.status === statusCode.updated)
          toast.success(messagesAction.deleted);
        setProductSelected(undefined);
        screenUpdate();
      });
    }
  }

  return (
    <Modal
      show={openModal}
      onHide={() => setProductSelected(undefined)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Visualizar produto</Modal.Title>
      </Modal.Header>
      {!!productSelected && (
        <>
          <Modal.Body>
            <p className="mb-0">
              <b>Produto: </b>
              {productSelected.name}
            </p>
            <p className="mb-0 mt-3">
              <b>Pontos: </b>
              {productSelected.loyalty_points}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Row className="gx-3">
              <Col xs="auto">
                {productSelected.is_active ? (
                  <BtnDropdown
                    btn={messagesAction.inactive}
                    icon={faBan}
                    title={messagesAction.inactive}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.inactive +
                      " o produto?"
                    }
                    variant_color="warning"
                    handleAction={() => handleStatus(false)}
                  />
                ) : (
                  <BtnDropdown
                    btn={messagesAction.active}
                    icon={faCheck}
                    title={messagesAction.active}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.active +
                      " o produto?"
                    }
                    variant_color="success"
                    handleAction={() => handleStatus(true)}
                  />
                )}
              </Col>
              <Col xs="auto">
                <BtnDropdown
                  btn={messagesAction.delete}
                  icon={faTrash}
                  title={messagesAction.delete}
                  msg={
                    "Tem certeza que deseja " +
                    messagesAction.delete +
                    " o produto? Essa ação não pode ser desfeita."
                  }
                  variant_color="danger"
                  handleAction={handleDelete}
                />
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalView;
