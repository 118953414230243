import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { messagesAction } from "../../../beans/messages";
import { statusCode } from "../../../beans/enumerators";
import BtnDropdown from "../../../components/lists/BtnDropdown";
import { faBan, faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { UserDetail } from "../../user/interfaces/user";
import { deleteUser, updateUser } from "../../user/services/user";
import Avatar from "../../../components/lists/Avatar";
import { baseURL } from "../../../utils/axios";
import { formatPhone } from "../../../utils/format";

interface ModalViewProps {
  userSelected: UserDetail | undefined;
  setUserSelected: Function;
  screenUpdate: Function;
}

const ModalView = ({
  userSelected,
  setUserSelected,
  screenUpdate,
}: ModalViewProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(!!userSelected ? true : false);
  }, [userSelected]);

  function handleStatus(status: boolean) {
    document.getElementsByTagName("h1")[0].click();
    if (userSelected) {
      let newUser: UserDetail = { ...userSelected };
      if (newUser.login) newUser.login.is_active = status;
      var userData = new FormData();
      userData.append("data", JSON.stringify(newUser));
      updateUser(userData).then((response) => {
        if (response.status === statusCode.updated) {
          setUserSelected(newUser);
          screenUpdate();
        }
      });
    }
  }

  function handleDelete() {
    document.getElementsByTagName("h1")[0].click();
    if (userSelected?.uuid_user) {
      deleteUser(userSelected.uuid_user).then((response) => {
        if (response.status === statusCode.updated)
          toast.success(messagesAction.deleted);
        setUserSelected(undefined);
        screenUpdate();
      });
    }
  }

  return (
    <Modal show={openModal} onHide={() => setUserSelected(undefined)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Visualizar vendedor</Modal.Title>
      </Modal.Header>
      {!!userSelected && (
        <>
          <Modal.Body>
            {!!userSelected.image && (
              <div className="mb-3">
                <Avatar
                  big={true}
                  name={userSelected.name}
                  src={baseURL + "/uploads/photos/" + userSelected.image}
                />
              </div>
            )}
            <p className="mb-0">
              <b>Nome: </b>
              {userSelected.name}
            </p>
            <p className="mb-0 mt-3">
              <b>E-mail: </b>
              {userSelected.login?.email}
            </p>
            <p className="mb-0 mt-3">
              <b>Telefone / Whatsapp: </b>
              {userSelected.phone ? formatPhone(userSelected.phone) : ""}
            </p>
            <p className="mb-0 mt-3">
              <b>Loja: </b>
              {userSelected.company?.name} ({userSelected.company?.city?.name}/
              {userSelected.company?.city?.state.name})
            </p>
            <p className="mb-0 mt-3">
              <b>Pontos: </b>
              {userSelected.loyalty_points_total}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Row className="gx-3">
              <Col xs="auto">
                {userSelected.login?.is_active ? (
                  <BtnDropdown
                    btn={messagesAction.inactive}
                    icon={faBan}
                    title={messagesAction.inactive}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.inactive +
                      " o vendedor? O mesmo não terá mais acesso ao sistema."
                    }
                    variant_color="warning"
                    handleAction={() => handleStatus(false)}
                  />
                ) : (
                  <BtnDropdown
                    btn={messagesAction.active}
                    icon={faCheck}
                    title={messagesAction.active}
                    msg={
                      "Tem certeza que deseja " +
                      messagesAction.active +
                      " o vendedor? O mesmo terá acesso ao sistema."
                    }
                    variant_color="success"
                    handleAction={() => handleStatus(true)}
                  />
                )}
              </Col>
              <Col xs="auto">
                <BtnDropdown
                  btn={messagesAction.delete}
                  icon={faTrash}
                  title={messagesAction.delete}
                  msg={
                    "Tem certeza que deseja " +
                    messagesAction.delete +
                    " o vendedor? Essa ação não pode ser desfeita."
                  }
                  variant_color="danger"
                  handleAction={handleDelete}
                />
              </Col>
            </Row>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default ModalView;
