import { Helmet } from "react-helmet-async";

import { Container, Card } from "react-bootstrap";

import Header from "../../components/lists/Header";
import FormProduct from "./components/FormProduct";

const ProductCreate = () => (
  <>
    <Helmet title="Adicionar produto" />
    <Container fluid className="p-0">
      <Header title="Adicionar produto" />
      <Card className="p-3">
        <FormProduct />
      </Card>
    </Container>
  </>
);

export default ProductCreate;
