import { useEffect, useState } from "react";
import { Col, Row, Collapse, Card } from "react-bootstrap";
import FilterActive from "../../../components/lists/FilterActive";
import SelectState from "./SelectState";
import SelectCity from "./SelectCity";

interface FilterProps {
  verifyFilters: Function;
  openFilters: boolean;
}

const Filter = ({ verifyFilters, openFilters }: FilterProps) => {
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");

  useEffect(() => {
    verifyFilters("id_state", selectedState);
  }, [selectedState]);
  useEffect(() => {
    verifyFilters("id_city", selectedCity);
  }, [selectedCity]);

  return (
    <Collapse in={openFilters} className="mq-filter p-3">
      <Card>
        <Row className="mt-n3">
          <FilterActive verifyFilters={verifyFilters} />
          <Col sm="auto" className="mt-3">
            <label className="form-label">Tem vendedores?</label>
            <select
              className="form-control form-select input-filter"
              onChange={(e) => verifyFilters("exist_user", e.target.value)}
            >
              <option value="">Todos</option>
              <option value="true">Sim</option>
              <option value="false">Não</option>
            </select>
          </Col>
          <Col sm="auto" className="mt-3">
            <div className="input-filter">
              <SelectState
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                showAll={true}
                selectAll={true}
              />
            </div>
          </Col>
          <Col sm="auto" className="mt-3">
            <div className="input-filter">
              <SelectCity
                selectedState={selectedState}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                showAll={true}
                selectAll={true}
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Collapse>
  );
};

export default Filter;
