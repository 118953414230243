import { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import Avatar from "../../../components/lists/Avatar";
import { baseURL } from "../../../utils/axios";
import useToken from "../../../utils/hooks/useToken";
import { UserDetail } from "../../user/interfaces/user";
import { getUserAll } from "../../user/services/user";
import { account_type } from "../../../beans/enumerators";

const Ranking = () => {
  const { isAdmin } = useToken();
  const [list, setList] = useState<Array<UserDetail>>([]);

  useEffect(() => {
    let filters = {
      account_type: account_type.client,
      take: "10",
      is_active: true,
    };
    getUserAll(filters).then((response) => setList(response.list));
  }, []);

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Card.Title className="mb-0">Ranking - Top 10 Vendedores</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex py-0">
        <Table striped className="my-0 mq-table-big">
          <thead>
            <tr>
              <th className="col-md-1"></th>
              <th>Vendedor</th>
              <th className="col-md-5">Loja</th>
              <th>Pontos</th>
            </tr>
          </thead>
          <tbody>
            {!!list &&
              list.map((item, key) => (
                <tr key={key}>
                  <td>#{key + 1}</td>
                  <td>
                    <Avatar
                      src={
                        item.image
                          ? baseURL + "/uploads/photos/" + item.image
                          : ""
                      }
                      name={item.name}
                      showName={true}
                    />
                  </td>
                  <td>
                    {item.company?.name +
                      " (" +
                      item.company?.city?.name +
                      "/" +
                      item.company?.city?.state.name +
                      ")"}
                  </td>
                  <td>{item.loyalty_points_total + " pontos"}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Card.Body>
      <Card.Footer>
        {isAdmin && (
          <Link to={"/vendedores"}>
            <span className="d-block text-muted text-center">Ver todos</span>
          </Link>
        )}
      </Card.Footer>
    </Card>
  );
};

export default Ranking;
