import { useEffect, useState } from "react";

import { UserDetail } from "../../user/interfaces/user";
import { getUserAll } from "../../user/services/user";

interface SelectUserProps {
  selectedCompany: string;
  selectedUser: string;
  setSelectedUser: Function;
}

const SelectUser = ({
  selectedCompany,
  selectedUser,
  setSelectedUser,
}: SelectUserProps) => {
  const [list, setList] = useState<Array<UserDetail>>([]);

  function updateUser() {

    if (selectedCompany)
      getUserAll({
        take: 100,
        is_active: true,
        uuid_company: selectedCompany,
      }).then((response) => setList(response.list));
    else setList([]);

    setSelectedUser("");
  }

  useEffect(() => {
    updateUser();
  }, [selectedCompany]);

  return (
    <>
      <label className="form-label">Vendedor</label>
      <select
        className="form-control form-select"
        onChange={(e) => setSelectedUser(e.target.value)}
        value={selectedUser}
      >
        <option value="">Todos</option>
        {!!list.length ? (
          list.map((item) => (
            <option value={item.uuid_user}>{item.name}</option>
          ))
        ) : (
          <option value="" disabled>
            Selecione a loja
          </option>
        )}
      </select>
    </>
  );
};

export default SelectUser;
