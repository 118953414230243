import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { account_type, itens_per_page } from "../../beans/enumerators";
import { messagesAction } from "../../beans/messages";
import Avatar from "../../components/lists/Avatar";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import { baseURL } from "../../utils/axios";
import { UserDetail } from "./interfaces/user";
import { getUserAll } from "./services/user";

const UserList = () => {
  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<UserDetail>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    account_type: account_type.admin,
    name: "",
    page: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "page") newFilters["page"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    getUserAll(filters).then((response) => {
      setList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  return (
    <>
      <Helmet title="Usuários" />
      <Container fluid className="p-0">
        <Header title="Usuários" verifyFilters={verifyFilters} />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <p className="d-md-none pt-3 ps-3">
            <b>Lista de usuários</b>
          </p>
          <Table striped className="mq-table-big">
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!list &&
                list.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <Avatar
                        src={
                          item.image
                            ? baseURL + "/uploads/photos/" + item.image
                            : ""
                        }
                        name={item.name}
                        showName={true}
                      />
                    </td>
                    <td>{item.login?.email}</td>
                    <td>
                      {item.login?.is_active ? (
                        <Badge bg="success">Ativo</Badge>
                      ) : (
                        <Badge bg="danger">Inativo</Badge>
                      )}
                    </td>
                    <td className="text-md-end mq-actions">
                      <Link to={"/usuarios/" + item.uuid_user}>
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faPen} />
                          <span className="ps-1">{messagesAction.edit}</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.page}
          verifyFilters={verifyFilters}
        />
      </Container>
    </>
  );
};

export default UserList;
