import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";

const Parent = ({ children }: PropsWithChildren) => (
  <>
    {children}
    <Outlet />
  </>
);

export default Parent;
