import { faFileExcel, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row } from "react-bootstrap";
import { File, Filter, Search } from "react-feather";

import { messagesAction } from "../../beans/messages";

interface HeaderProps {
  title: string;
  verifyFilters?: Function;
  openFilters?: boolean;
  setOpenFilters?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

const Header = ({
  title,
  verifyFilters,
  openFilters,
  setOpenFilters,
  setOpenModal,
  children,
}: HeaderProps) => {
  let timeout: ReturnType<typeof setTimeout>;
  function nameFilter(value: string) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (!!verifyFilters) verifyFilters("name", value);
    }, 1000);
  }

  return (
    <Row className="align-items-center mb-3">
      <Col className="mb-2">
        <h1 className="h3 mb-0">{title}</h1>
      </Col>
      <Col sm="auto" className="mb-2">
        <Row className="gx-3">
          {!!verifyFilters && (
            <Col xm="auto">
              <div className="position-relative input-filter">
                <input
                  type="text"
                  placeholder="Pesquisar por nome"
                  className="form-control icon-input-filter"
                  onChange={(e) => nameFilter(e.target.value)}
                />
                <Search className="absolute-top" />
              </div>
            </Col>
          )}
          {!!setOpenFilters && (
            <Col xm="auto">
              <Button
                variant="primary"
                className="shadow-sm d-flex align-items-center"
                onClick={() => setOpenFilters(!openFilters)}
              >
                <Filter size={16} />
                <span className="ps-1">Filtros</span>
              </Button>
            </Col>
          )}
          {!!setOpenModal && (
            <Col xm="auto">
              <Button
                variant="success"
                className="shadow-sm d-flex align-items-center"
                onClick={() => setOpenModal(true)}
              >
                <FontAwesomeIcon icon={faFileExcel} />
                <span className="ps-1">Importar</span>
              </Button>
            </Col>
          )}

          <>{children}</>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
