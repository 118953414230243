// Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import ClientGuard from "./components/guards/ClientGuard";
import GuestGuard from "./components/guards/GuestGuard";
// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// Auth
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import NewPassword from "./pages/auth/NewPassword";
import ResetPassword from "./pages/auth/ResetPassword";
// Pages
import Dashboard from "./pages/Dashboard";
import Page404 from "./pages/error/Page404";
import Page500 from "./pages/error/Page500";
import Seller from "./pages/Seller";
import Parent from "./components/Parent";
import SaleCreate from "./pages/sale/Create";
import SaleList from "./pages/sale/ListAll";
import ProductCreate from "./pages/product/Create";
import ProductList from "./pages/product/ListAll";
import ProductUpdate from "./pages/product/Update";
import CompanyCreate from "./pages/company/Create";
import CompanyList from "./pages/company/ListAll";
import CompanyUpdate from "./pages/company/Update";
import UserCreate from "./pages/user/Create";
import UserList from "./pages/user/ListAll";
import UserUpdate from "./pages/user/Update";
import Config from "./pages/Config";

const routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "vendedores",
        element: (
          <AdminGuard>
            <Seller />
          </AdminGuard>
        ),
      },
      {
        path: "vendas",
        element: <Parent />,
        children: [
          {
            path: "",
            element: <SaleList />,
          },
          {
            path: "adicionar",
            element: (
              <ClientGuard>
                <SaleCreate />
              </ClientGuard>
            ),
          },
        ],
      },
      {
        path: "produtos",
        element: <Parent />,
        children: [
          {
            path: "",
            element: <ProductList />,
          },
          {
            path: "adicionar",
            element: (
              <AdminGuard>
                <ProductCreate />
              </AdminGuard>
            ),
          },
          {
            path: ":id",
            element: (
              <AdminGuard>
                <ProductUpdate />
              </AdminGuard>
            ),
          },
        ],
      },
      {
        path: "lojas",
        element: (
          <AdminGuard>
            <Parent />
          </AdminGuard>
        ),
        children: [
          {
            path: "",
            element: <CompanyList />,
          },
          {
            path: "adicionar",
            element: <CompanyCreate />,
          },
          {
            path: ":id",
            element: <CompanyUpdate />,
          },
        ],
      },
      {
        path: "usuarios",
        element: <Parent />,
        children: [
          {
            path: "",
            element: (
              <AdminGuard>
                <UserList />
              </AdminGuard>
            ),
          },
          {
            path: "adicionar",
            element: (
              <AdminGuard>
                <UserCreate />
              </AdminGuard>
            ),
          },
          {
            path: ":id",
            element: <UserUpdate />,
          },
        ],
      },
      {
        path: "config",
        element: (
          <AdminGuard>
            <Config />
          </AdminGuard>
        ),
      },
    ],
  },
  {
    path: "acesso",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "entrar",
        element: <SignIn />,
      },
      {
        path: "cadastrar",
        element: <SignUp />,
      },
      {
        path: "recuperar-senha",
        element: <ResetPassword />,
      },
      {
        path: "nova-senha/:hash",
        element: <NewPassword />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
