import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { itens_per_page } from "../../beans/enumerators";
import { messagesAction } from "../../beans/messages";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import Filter from "./components/Filter";
import { Company } from "./interfaces/company";
import { getCompanyAll } from "./services/company";
import ModalImport from "./components/ModalImport";
import ModalView from "./components/ModalView";
import { Link } from "react-router-dom";

const CompanyList = () => {
  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<Company>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    is_active: "true",
    exist_user: "",
    id_state: "",
    id_city: "",
    name: "",
    page: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "page") newFilters["page"] = "1";
    if (key === "id_state") newFilters["id_city"] = "";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    getCompanyAll(filters).then((response) => {
      setList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [companySelected, setCompanySelected] = useState<Company>();

  return (
    <>
      <Helmet title="Lojas" />
      <Container fluid className="p-0">
        <Header
          title="Lojas"
          verifyFilters={verifyFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
          setOpenModal={setOpenModal}
        />
        <Filter openFilters={openFilters} verifyFilters={verifyFilters} />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <p className="d-md-none pt-3 ps-3">
            <b>Lista de lojas</b>
          </p>
          <Table striped className="mq-table-big">
            <thead>
              <tr>
                <th>Código</th>
                <th className="col-md-5">Loja</th>
                <th>Local</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!list &&
                list.map((item, key) => (
                  <tr key={key}>
                    <td>
                      {item.is_active ? (
                        item.code
                      ) : (
                        <Badge bg="danger">Inativo</Badge>
                      )}
                    </td>
                    <td>{item.name}</td>
                    <td>
                      {item.city?.name}/{item.city?.state.name}
                    </td>
                    <td className="text-md-end mq-actions">
                      <Link to={"/lojas/" + item.uuid_company}>
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faPen} />
                          <span className="ps-1">{messagesAction.edit}</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.page}
          verifyFilters={verifyFilters}
        />
      </Container>
      <ModalImport
        openModal={openModal}
        setOpenModal={setOpenModal}
        screenUpdate={screenUpdate}
      />
      <ModalView
        companySelected={companySelected}
        setCompanySelected={setCompanySelected}
        screenUpdate={screenUpdate}
      />
    </>
  );
};

export default CompanyList;
