import { SidebarItemsType } from "./interfaces/sidebar";

import { faUsers, faBoxes, faUser, faLock, faShoppingCart, faCog, faStore, faHome } from "@fortawesome/free-solid-svg-icons";

const homeSection = [
  {
    href: "/",
    icon: faHome,
    title: "Dashboard",
  },
] as SidebarItemsType[];

const accountSection = [
  {
    href: "/usuarios/perfil/",
    icon: faUser,
    title: "Perfil",
  },
] as SidebarItemsType[];

const infoSection = [
  {
    href: "/vendedores",
    icon: faUsers,
    title: "Vendedores",
  },
  {
    href: "/vendas",
    icon: faShoppingCart,
    title: "Vendas",
  },
  {
    href: "/produtos",
    icon: faBoxes,
    title: "Produtos",
    children: [
      {
        href: "/produtos",
        title: "Ver todos",
      },
      {
        href: "/produtos/adicionar",
        title: "Adicionar novo",
      },
    ],
  },
  {
    href: "/lojas",
    icon: faStore,
    title: "Lojas",
    children: [
      {
        href: "/lojas",
        title: "Ver todas",
      },
      {
        href: "/lojas/adicionar",
        title: "Adicionar nova",
      },
    ],
  },
] as SidebarItemsType[];

const configSection = [
    {
      href: "/usuarios",
      icon: faLock,
      title: "Usuários",
      children: [
        {
          href: "/usuarios",
          title: "Ver todos",
        },
        {
          href: "/usuarios/adicionar",
          title: "Adicionar novo",
        },
      ],
    },
    {
      href: "/config",
      icon: faCog,
      title: "Configurações",
    },
  ] as SidebarItemsType[];

const navItems = [
  {
    title: "Início",
    pages: homeSection,
  },
  {
    title: "Gerenciar",
    pages: infoSection,
  },
  {
    title: "Configurar",
    pages: configSection,
  },
  {
    title: "Minha conta",
    pages: accountSection,
  },
];

export default navItems;