import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import { statusCode } from "../../../beans/enumerators";
import { messagesAction, messagesForm } from "../../../beans/messages";
import Yup from "../../error/Yup";
import { ProductForm, Product } from "../interfaces/product";
import { getProduct, postProduct, updateProduct } from "../services/product";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface ProductFormProps {
  productSelected?: Product;
  setProductSelected?: Function;
}

const ProductFormData = ({
  productSelected,
  setProductSelected,
}: ProductFormProps) => {
  const [initialValues, setInitialValues] = useState<ProductForm>({
    submit: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (productSelected)
      setInitialValues({
        submit: false,
        ...productSelected,
      });
  }, [productSelected]);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        name: Yup.string().max(150).required(messagesForm.required),
        loyalty_points: Yup.number().required(messagesForm.required),
      })}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        let response = !!productSelected
          ? await updateProduct(values)
          : await postProduct(values);
        if (
          response.status !== statusCode.updated &&
          response.status !== statusCode.created
        ) {
          setStatus({ success: false });
          setSubmitting(false);
        } else {
          if (
            !!productSelected &&
            !!setProductSelected &&
            values.uuid_product
          ) {
            console.log("qual o problema ");
            toast.success(messagesAction.success);
            getProduct(values.uuid_product).then((response) =>
              setProductSelected(response)
            );
          } else {
            toast.success(messagesAction.created);
            navigate("/produtos/");
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Row className="gx-2">
            <Col xl="4" md="6">
              <Form.Group className="mb-3">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="name"
                  value={values.name}
                  isInvalid={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col xl="2" md="3" sm="6">
              <Form.Group className="mb-3">
                <Form.Label>Pontos</Form.Label>
                <Form.Control
                  size="lg"
                  type="number"
                  name="loyalty_points"
                  value={values.loyalty_points}
                  isInvalid={Boolean(
                    touched.loyalty_points && errors.loyalty_points
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.loyalty_points && (
                  <Form.Control.Feedback type="invalid">
                    {errors.loyalty_points}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <div className="mt-1">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              {messagesAction.save}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProductFormData;
