import { useEffect, useState } from "react";
import { Company } from "../interfaces/company";
import { getCompanyAll } from "../services/company";

interface SelectCompanyProps {
  selectedCity: string;
  selectedCompany: string;
  setSelectedCompany: Function;
  showAll?: boolean;
  selectAll?: boolean;
}

const SelectCompany = ({
  selectedCity,
  selectedCompany,
  setSelectedCompany,
  showAll,
  selectAll,
}: SelectCompanyProps) => {
  const [list, setList] = useState<Array<Company>>([]);

  function updateCompany() {
    if (selectedCity)
      getCompanyAll({ take: 100, is_active: true, id_city: selectedCity }).then(
        (response) => setList(response.list)
      );
    else setList([]);

    setSelectedCompany("");
  }
  useEffect(() => {
    updateCompany();
  }, [selectedCity]);

  return (
    <>
      <label className="form-label">Loja</label>
      <select
        className="form-control form-select"
        onChange={(e) => setSelectedCompany(e.target.value)}
        value={selectedCompany}
      >
        {!!selectAll && (
          <option value="">{showAll ? "Todas" : "Selecione"}</option>
        )}
        {!!list.length ? (
          list.map((item) => (
            <option value={item.uuid_company}>{item.name}</option>
          ))
        ) : (
          <option value="" disabled>
            Selecione a cidade
          </option>
        )}
      </select>
    </>
  );
};

export default SelectCompany;
