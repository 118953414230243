import jwtDecode from "jwt-decode";
import moment from "moment";
import axios from "./axios";

const tokenName = process.env.REACT_APP_TOKEN || "accessToken";

const getToken = () => {
  return localStorage.getItem(tokenName);
};

const isValidToken = (accessToken: string | null) => {
  if (!accessToken) return false;
  let decoded = jwtDecode<{ exp: number }>(accessToken);
  let currentTime = moment().unix();

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;
  window.clearTimeout(expiredTimer);

  let currentTime = moment().unix();
  let timeLeft = (exp - currentTime) * 1000;

  expiredTimer = window.setTimeout(() => {
    unsetSession();
  }, timeLeft);
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem(tokenName, accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    var { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    unsetSession();
  }
};

const unsetSession = () => {
  localStorage.removeItem(tokenName);
  delete axios.defaults.headers.common.Authorization;
  window.location.reload();
};

export { getToken, isValidToken, setSession, unsetSession };