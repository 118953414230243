import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { messagesAction, messagesForm } from "../../beans/messages";
import Header from "../../components/lists/Header";
import { ConfigData, ConfigForm } from "./interfaces/config";
import { getConfig, updateConfig } from "./services/config";
import { baseURL } from "../../utils/axios";
import Yup from "../error/Yup";
import { statusCode } from "../../beans/enumerators";
import { toast } from "react-toastify";
import defaultBanner from "./assets/img/default.png";

const Config = () => {
  const [theConfig, setTheConfig] = useState<ConfigData>();
  const [initialValues, setInitialValues] = useState<ConfigForm>({
    submit: false,
  });

  const [srcImage, setSrcImage] = useState<string | ArrayBuffer | null>("");
  const [sendImage, setSendImage] = useState<any>();
  function handleImage(image: FileList | null) {
    if (image) {
      let selectedFile = image[0];
      setSendImage(image[0]);
      var reader = new FileReader();
      reader.onloadend = function () {
        setSrcImage(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setSendImage(null);
    }
  }
  function handleDeleteImage() {
    setSrcImage(null);
    setSendImage(null);
  }

  function screenUpdate() {
    getConfig().then((response) => setTheConfig(response));
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  useEffect(() => {
    if (theConfig)
      setInitialValues({
        submit: false,
        ...theConfig,
      });
    if (theConfig?.banner)
      setSrcImage(baseURL + "/uploads/banner/" + theConfig.banner);
  }, [theConfig]);

  return (
    <>
      <Helmet title="Configurações" />
      <Container fluid className="p-0">
        <Header title="Configurações" />
        <Card className="p-3">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape({
              date_min_sale: Yup.string().required(messagesForm.required),
            })}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              if (!srcImage && values.banner) values.banner = null;
              var configData = new FormData();
              if (sendImage) configData.append("banner", sendImage);
              configData.append("data", JSON.stringify(values));
              setSendImage(null);

              await updateConfig(configData).then((response) => {
                if (response.status !== statusCode.updated) {
                  setStatus({ success: false });
                  setSubmitting(false);
                } else {
                  toast.success(messagesAction.success);
                  screenUpdate();
                }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Banner comunicação</Form.Label>
                  <Row className="mt-2">
                    <Col md="auto" className="order-md-2">
                      <div>
                        <Button variant="primary">
                          <label htmlFor="image_send">Alterar imagem</label>
                        </Button>
                        <input
                          type="file"
                          id="image_send"
                          onChange={(e) => handleImage(e.target.files)}
                          className="d-none"
                        />
                      </div>
                      <div className="mt-2">
                        <Button variant="light" onClick={handleDeleteImage}>
                          Excluir imagem
                        </Button>
                      </div>
                      <p className="mt-2">
                        <small>Tamanho recomendado de 760 x 175 pixels</small>
                      </p>
                    </Col>
                    <Col md="6" className="d-flex order-md-1">
                      <Card className="illustration-app flex-fill">
                        <Card.Body className="p-0 d-flex flex-fill">
                          <img
                            src={srcImage ? srcImage.toString() : defaultBanner}
                            alt="fundo"
                            className="illustration-bg w-100"
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Form.Group>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3">
                      <Form.Label>Link do banner</Form.Label>
                      <Form.Control
                        size="lg"
                        name="banner_link"
                        value={values.banner_link?.toString()}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md="auto">
                    <Form.Group className="mb-3">
                      <Form.Label>Data de corte para vendas</Form.Label>
                      <Form.Control
                        size="lg"
                        type="date"
                        name="date_min_sale"
                        value={values.date_min_sale}
                        isInvalid={Boolean(
                          touched.date_min_sale && errors.date_min_sale
                        )}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.date_min_sale && (
                        <Form.Control.Feedback type="invalid">
                          {errors.date_min_sale}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <div className="mt-4">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    {messagesAction.save}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </>
  );
};

export default Config;
