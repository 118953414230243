import React from "react";
import { Dropdown } from "react-bootstrap";
import { Settings } from "react-feather";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../utils/axios";

import useToken from "../../utils/hooks/useToken";

import { unsetSession } from "../../utils/token";
import Avatar from "../lists/Avatar";

const NavbarUser = () => {
  const { user } = useToken();
  const navigate = useNavigate();

  return (
    <Dropdown className="nav-item" align="end">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <Avatar
            src={
              user?.user.image
                ? baseURL + "/uploads/photos/" + user.user.image
                : ""
            }
            name={user?.user.name ? user.user.name.split(" ")[0] : ""}
            showName={true}
          />
          <span className="pe-2"></span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => navigate("/usuarios/" + user?.user.uuid_user + "/")}
        >
          Minha conta
        </Dropdown.Item>
        <Dropdown.Item onClick={unsetSession}>Sair</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
