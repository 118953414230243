import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faFileMedical, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { itens_per_page } from "../../beans/enumerators";
import { messagesAction } from "../../beans/messages";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import useToken from "../../utils/hooks/useToken";
import Filter from "./components/Filter";
import ModalSelectReport from "./components/ModalSelectReport";
import ModalView from "./components/ModalView";
import { Sale } from "./interfaces/sale";
import { getSaleAll } from "./services/sale";

const SaleList = () => {
  const { isAdmin, user } = useToken();

  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<Sale>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [showReportModal, setShowReportModal] = useState(false);
  const [filters, setFilters] = useState<any>({
    is_active: !isAdmin ? "" : "true",
    id_state: "",
    id_city: "",
    uuid_company: "",
    uuid_user: !isAdmin && user?.user.uuid_user ? user.user.uuid_user : "",
    page: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "page") newFilters["page"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    if (user && user.user.uuid_user) {
      getSaleAll(filters).then((response) => {
        setList(response.list);
        setNList(response.total);
        response.total > 0
          ? setPagination(Math.ceil(response.total / itens_per_page.default))
          : setPagination(0);
      });
    }
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);
  const [saleSelected, setSaleSelected] = useState<Sale>();

  return (
    <>
      <Helmet title={isAdmin ? "Vendas" : "Minhas vendas"} />
      <Container fluid className="p-0">
        <Header
          title={isAdmin ? "Vendas" : "Minhas vendas"}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        >
          {isAdmin && !!list.length && (
            <Col xm="col-sm-4">
              <Button
                variant="info"
                className="shadow-sm d-flex align-items-center"
                onClick={() => setShowReportModal(true)}
              >
                <FontAwesomeIcon icon={faFileMedical} />
                <span className="ps-1">Relatórios</span>
              </Button>
            </Col>
          )}
        </Header>

        <Filter
          openFilters={openFilters}
          verifyFilters={verifyFilters}
          isAdmin={isAdmin}
        />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <p className="d-md-none pt-3 ps-3">
            <b>Lista de vendas</b>
          </p>
          <Table striped className="mq-table-big">
            <thead>
              <tr>
                {isAdmin && <th>Vendedor</th>}
                <th>Data</th>
                <th>Quantidade</th>
                <th>Pontos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!list &&
                list.map((item, key) => (
                  <tr key={key}>
                    {isAdmin && <td>{item.user?.name}</td>}
                    <td>
                      {item.date ? moment(item.date).format("DD/MM/YYYY") : ""}
                    </td>
                    <td>{item.saleProducts?.length} produtos</td>
                    <td>
                      {item.is_active ? (
                        item.saleProducts?.reduce(
                          (total, product) =>
                            total + product.recorded_loyalty_points,
                          0
                        ) + " pontos"
                      ) : (
                        <Badge bg="danger">Inativo</Badge>
                      )}
                    </td>
                    <td className="text-md-end mq-actions">
                      <Button
                        variant="primary"
                        onClick={() => setSaleSelected(item)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                        <span className="ps-1">{messagesAction.view}</span>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.page}
          verifyFilters={verifyFilters}
        />
        <ModalView
          saleSelected={saleSelected}
          setSaleSelected={setSaleSelected}
          screenUpdate={screenUpdate}
          isAdmin={isAdmin}
        />
        {isAdmin && (
          <ModalSelectReport
            setOpenModal={setShowReportModal}
            openModal={showReportModal}
            userSelected={filters.uuid_user}
          />
        )}
      </Container>
    </>
  );
};

export default SaleList;
