import { useState, useEffect } from "react";

import { Pagination } from "react-bootstrap";

interface PaginationProps {
  pagination: number;
  filter: any;
  verifyFilters: Function;
}

const PaginationCustom = ({
  pagination,
  filter,
  verifyFilters,
}: PaginationProps) => {
  const [paginationNumbers, setPaginationNumbers] = useState<any>();
  const filterName = "page";

  function updatePagination(qtd: number) {
    let paginationArrayPag: Array<number> = [];
    for (let i = 0; i < qtd; i++) {
      paginationArrayPag.push(i + 1);
    }
    setPaginationNumbers(() => (
      <>
        {paginationArrayPag.map((item, key) => (
          <Pagination.Item
            active={item === Number(filter)}
            key={key}
            onClick={() => verifyFilters(filterName, item.toString())}
          >
            {item}
          </Pagination.Item>
        ))}
      </>
    ));
  }

  useEffect(() => {
    updatePagination(pagination);
  }, [filter, pagination]);

  return (
    <>
      {pagination > 1 && (
        <Pagination className="mt-5 justify-content-end">
          {filter > 1 && (
            <Pagination.Prev
              onClick={() =>
                verifyFilters(filterName, (Number(filter) - 1).toString())
              }
            />
          )}
          {paginationNumbers}
          {filter < pagination && (
            <Pagination.Next
              onClick={() =>
                verifyFilters(filterName, (Number(filter) + 1).toString())
              }
            />
          )}
        </Pagination>
      )}
    </>
  );
};

export default PaginationCustom;
