import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { account_type, itens_per_page } from "../../beans/enumerators";
import { messagesAction } from "../../beans/messages";
import Avatar from "../../components/lists/Avatar";
import Header from "../../components/lists/Header";
import PaginationCustom from "../../components/lists/Pagination";
import Filter from "./components/Filter";
import { UserDetail } from "../user/interfaces/user";
import { getUserAll } from "../user/services/user";
import { baseURL } from "../../utils/axios";
import ModalView from "./components/ModalView";

const SellerList = () => {
  const [nList, setNList] = useState<number>(0);
  const [list, setList] = useState<Array<UserDetail>>([]);
  const [pagination, setPagination] = useState<number>(0);
  const [filters, setFilters] = useState<any>({
    account_type: account_type.client,
    id_state: "",
    id_city: "",
    uuid_company: "",
    is_active: "true",
    name: "",
    page: "1",
  });

  function verifyFilters(key: string, newValue: string) {
    let newFilters = filters;
    newFilters[key] = newValue;
    if (key !== "page") newFilters["page"] = "1";
    setFilters(newFilters);
    screenUpdate();
  }

  function screenUpdate() {
    getUserAll(filters).then((response) => {
      setList(response.list);
      setNList(response.total);
      response.total > 0
        ? setPagination(Math.ceil(response.total / itens_per_page.default))
        : setPagination(0);
    });
  }
  useEffect(() => {
    screenUpdate();
  }, []);

  const [openFilters, setOpenFilters] = useState(false);
  const [userSelected, setUserSelected] = useState<UserDetail>();

  return (
    <>
      <Helmet title="Vendedores" />
      <Container fluid className="p-0">
        <Header
          title="Vendedores"
          verifyFilters={verifyFilters}
          openFilters={openFilters}
          setOpenFilters={setOpenFilters}
        />
        <Filter openFilters={openFilters} verifyFilters={verifyFilters} />
        <div className="mt-4">{nList} registros</div>
        <Card className="flex-fill w-100 mt-3">
          <p className="d-md-none pt-3 ps-3">
            <b>Lista de vendedores</b>
          </p>
          <Table striped className="mq-table-big">
            <thead>
              <tr>
                <th className="col-md-1"></th>
                <th>Vendedor</th>
                <th className="col-md-5">Loja</th>
                <th>Pontos</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!!list &&
                list.map((item, key) => (
                  <tr key={key}>
                    <td>{!!item.login?.is_active && "#" + (key + 1)}</td>
                    <td>
                      <Avatar
                        src={
                          item.image
                            ? baseURL + "/uploads/photos/" + item.image
                            : ""
                        }
                        name={item.name}
                        showName={true}
                      />
                    </td>
                    <td>
                      {item.company?.name +
                        " (" +
                        item.company?.city?.name +
                        "/" +
                        item.company?.city?.state.name +
                        ")"}
                    </td>
                    <td>
                      {item.login?.is_active ? (
                        item.loyalty_points_total + " pontos"
                      ) : (
                        <Badge bg="danger">Inativo</Badge>
                      )}
                    </td>
                    <td className="text-md-end mq-actions">
                      <Button
                        variant="primary"
                        onClick={() => setUserSelected(item)}
                      >
                        <FontAwesomeIcon icon={faEye} />
                        <span className="ps-1">{messagesAction.view}</span>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Card>
        <PaginationCustom
          pagination={pagination}
          filter={filters.page}
          verifyFilters={verifyFilters}
        />
        <ModalView
          userSelected={userSelected}
          setUserSelected={setUserSelected}
          screenUpdate={screenUpdate}
        />
      </Container>
    </>
  );
};

export default SellerList;
