import { AxiosResponse } from "axios";

import axiosInstance from "../../../utils/axios";
import { verifyData, verifyResponse } from "../../../utils/verify-response";
import { ConfigData } from "../interfaces/config";

export async function getConfig(): Promise<ConfigData | undefined> {
    return axiosInstance.get('/config/').then(response => 
      verifyData(response)
    ).catch(error => verifyData(error));
  }
  
  export async function updateConfig(configData: any): Promise<AxiosResponse> {
    return axiosInstance.patch('/config/', configData).then(response => 
      verifyResponse(response)
    ).catch(error => verifyResponse(error));
  }